<template>
  <div class="content">
    <div class="OrderListWrap">
      <van-tabs v-model="active" sticky @click="tabClick">
        <van-tab v-for="(tabItem,index) in orderTitle" :key="index" :title="tabItem.title">
          <van-list
            v-model="loading"
            :finished="finished"
            :error.sync="error"
            :immediate-check="false"
            offset="300"
            error-text="请求失败，点击重新加载"
            finished-text=""
            @load="onLoad"
          >
            <div v-if="isData" class="noDataWrap">
              <img
                class="noDataImg"
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
                alt=""
              >
              <p class="noDataText">您还没有相关订单</p>
            </div>
            <div v-else class="OrderListContent">
              <!--                            手机充值-->
              <div v-if="orderType === 2">
                <div v-for="(item,itemIndex) in orderList" :key="itemIndex" class="TopUpOrderDetails">
                  <div class="TopUpNameStatus">
                    <p>{{ item.goodsName }}</p>
                    <div>
                      <!--                                            0全部  5 待付款 10  已支付 20已充值  23 充值失败  25  取消-->
                      <p v-if="item.status === 20" style="color: #E33F44;">{{ item.statusText }}</p>
                      <p v-if="item.status === 25" style="color: #999999;">{{ item.statusText }}</p>
                      <p v-if="item.status === 5" style="color: #EF8F49;">{{ item.statusText }}</p>
                      <p v-if="item.status === 10" style="color: #E33F44;">{{ item.statusText }}</p>
                      <p v-if="item.status === 23" style="color: #999999;">{{ item.statusText }}</p>
                    </div>
                  </div>
                  <div class="TopUpNumber">订单：{{ item.orderNo }}</div>
                  <div class="TopUpDatePrice">
                    <p>时间：{{ item.createTime }}</p>
                    <p>支付金额：{{ item.orderTotal }}</p>
                  </div>
                  <!--                                    待支付取消订单和支付按钮-->
                  <div v-if="item.status === 5" class="PayBtnWrap">
                    <div class="PayBtn ">
                      <p @click="CancelOrder(item)">取消订单</p>
                      <!--                                            <p @click="mobilePayOrder(item)">支付</p>-->
                    </div>
                  </div>
                </div>
              </div>
              <!--                            视频充值-->
              <div v-if="orderType === 3">
                <!--                                banner-->
                <!--                                <div class="videoBanner">-->
                <!--                                    <img :src="videoBannerImg" alt="">-->
                <!--                                </div>-->
                <div v-for="(item,itemIndex) in orderList" :key="itemIndex" class="videoTopUpOrderDetails">
                  <div @click="goVideoDetail(item)">
                    <div class="videoTopUpNameStatus">
                      <img :src="item.goodsImage" alt="">
                      <p>{{ item.goodsName }}</p>
                      <div>
                        <!--                                            0全部  5 待付款 10  已支付 20已充值  23 充值失败  25  取消-->
                        <p v-if="item.status === 20" style="color: #E33F44;">{{ item.statusText }}</p>
                        <p v-if="item.status === 25" style="color: #999999;">{{ item.statusText }}</p>
                        <p v-if="item.status === 5" style="color: #EF8F49;">{{ item.statusText }}</p>
                        <p v-if="item.status === 10" style="color: #E33F44;">{{ item.statusText }}</p>
                        <p v-if="item.status === 23" style="color: #999999;">{{ item.statusText }}</p>
                      </div>
                    </div>
                    <div class="videoTopUpNumber">订单：{{ item.orderNo }}</div>
                    <div class="videoTopUpDate">时间：{{ item.createTime }}</div>
                    <div class="videoTopUpSpecifications">
                      <p>规格：{{ item.skuName }}</p>
                      <p>支付金额：{{ item.orderTotal }}</p>
                    </div>
                  </div>
                  <!--                                    待支付取消订单和支付按钮-->
                  <div v-if="item.status === 5" class="PayBtnWrap">
                    <div class="PayBtn ">
                      <!--                                            <p @click="CancelOrder(item)">取消订单</p>-->
                      <!--                                            <p @click="videoPayOrder(item)">支付</p>-->
                    </div>
                  </div>
                </div>
              </div>
              <!--                            美团饿了么-->
              <div v-if="orderType === 5 || orderType === 6">
                <div v-for="(item,itemIndex) in orderList" :key="itemIndex" class="orderDetails">
                  <div class="orderSn">
                    订单号：{{ item.order_sn }}
                    <span @click="copyOrderSn(item.orderNo)">复制</span>
                  </div>
                  <div class="orderContentWrap">
                    <img :src="item.goods_thumbnail_url" alt="">
                    <div class="orderContent">
                      <div class="orderName">
                        <img :src="item.goods_thumbnail_url" alt="">
                        <p>{{ item.goods_name }}</p>
                      </div>
                      <div class="orderTitle">
                        <div>
                          <p><span>付款金额</span></p>
                          <p style="margin-top: 5px;color:#E33F44;"><span>¥</span><span
                            style="font-size: 15px;font-weight: 600;margin-left: 5px;"
                          >{{ item.order_amount }}</span></p>
                        </div>
                        <div>
                          <p><span>预估成长值</span></p>
                          <p style="margin-top: 5px;color:#E33F44;"><span>¥</span><span
                            style="font-size: 15px;font-weight: 600;margin-left: 5px;"
                          >{{ item.promotion_amount }}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="orderBtm">
                    <p class="orderDate">{{ item.order_create_time }}</p>
                    <div class="orderStatus">
                      <p v-if="item.cents_status === 0">未到账</p>
                      <p v-if="item.cents_status === 1">已到账</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { List, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Toast).use(List)
import {
  getMeiTuanOrder,
  getOrderv2List,
  videoOrderBanner,
  cancelV2Order,
  getVideoTopUpPay,
  getMobileTopUpPay
} from '@/services/comeOn.js'
import { weChatPay } from '@/utils/payment'
import { copyText } from '@/utils/common/utils'

export default {
  name: 'OrderList',
  components: {

  },
  data() {
    return {
      mid: '',
      active: 0, // tab默认选中1
      isData: true,
      orderTitle: [], // TitleList
      orderList: [], // 订单列表
      orderType: 0, // 订单类型
      // 下拉刷新参数说明
      down: {
        use: false// 是否开启下拉刷新功能
        // top:'50px',下拉刷新
      },
      // 上拉加载参数说明
      up: {
        use: true, // 是否开启上拉加载功能
        initCallBack: this.getMeituanOrder, // 初始化加载接口（调取初始化接口数据）
        callback: this.getMeituanOrder, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      videoBannerImg: '',
      page: 1,
      loading: false,
      finished: false,
      error: false
    }
  },
  created() {
    this.mid = window.localStorage.getItem('uid')
    this.orderType = this.$route.query ? Number(this.$route.query.type) : Number(this.$route.query.type)
    if (this.orderType === 5) { // 美团
      // status:0全部  1待分佣  2 已分佣
      // orderType 0 拼多多 1京东 2唯品会 3苏宁  5 美团 6 亚马逊7 淘宝 9 品牌券 11 叮当快药 12 饿了吗
      this.orderTitle = [
        {
          title: '全部',
          status: 0
        }, {
          title: '待到账',
          status: 1
        }, {
          title: '已到账',
          status: 2
        }
      ]
      this.$route.meta.title = '美团订单'
      this.getMeituanOrder()
    } else if (this.orderType === 2) { // 手机充值
      this.orderTitle = [
        {
          title: '全部',
          status: 0
        }, {
          title: '充值中',
          status: 1
        }, {
          title: '已充值',
          status: 2
        }
      ]
      this.$route.meta.title = '手机充值订单'
      this.getMobileOrder(0)
    } else if (this.orderType === 3) { // 视频充值
      this.orderTitle = [
        {
          title: '全部',
          status: 0
        }, {
          title: '充值中',
          status: 2
        }, {
          title: '已完成',
          status: 3
        }
      ]

      // {
      //     title:'待付款',
      //         status:1,
      // },
      this.$route.meta.title = '视频充值订单'
      // this.getVideoBanner();//banner
      this.getMobileOrder(0)
    }
  },
  mounted() {

  },
  methods: {
    tabClick(name, title) {
      this.name = name
      this.page = 1
      if (this.orderType === 3) { // 视频充值
        this.getMobileOrder(name)
      } else if (this.orderType === 2) { // 手机充值
        this.getMobileOrder(name)
      } else if (this.orderType === 5 || this.orderType === 6) { // 美团
        this.getMeituanOrder(name)
      }
    },
    // 滚动加载时触发，list组件定义的方法
    onLoad() {
      this.page++
      if (this.orderType === 3) { // 视频充值
        this.getMobileOrder(this.name)
      } else if (this.orderType === 2) { // 手机充值
        this.getMobileOrder(this.name)
      } else if (this.orderType === 5 || this.orderType === 6) { // 美团
        this.getMeituanOrder(this.name)
      }
    },
    // 美团订单
    getMeituanOrder(status) { // 0全部1待到账2已到账
      // const _this = this
      let orderType
      if (this.orderType === 5) {
        orderType = 5// 美团
      } else if (this.orderType === 6) {
        orderType = 12// 饿了么
      }
      getMeiTuanOrder({
        mid: window.localStorage.getItem('uid'),
        type: status, // 0全部  1待分佣  2 已分佣
        orderType: orderType, // 0 拼多多 1京东 2唯品会 3苏宁  5 美团 6 亚马逊7 淘宝 9 品牌券 11 叮当快药 12 饿了吗
        page: this.page,
        pageSize: 10
      }).then(res => {
        this.loading = false
        if (Number(res.code) === 200) {
          if (res.data.length < 1 || !res.data) {
            this.isData = true
            this.finished = true
            this.orderList = []
            return
          } else if (res.data.data.length < 10) {
            this.finished = true
            this.orderList = this.orderList.concat(res.data.data)
            return
          } else {
            this.isData = false
            this.orderList = this.orderList.concat(res.data.data)
          }
          this.loading = false
          this.refreshing = false
        }
      })
    },
    // 复制单号
    copyOrderSn(orderSn) {
      copyText(orderSn, () => {
        Toast('复制成功')
      })
    },
    // 手机/视频订单
    getMobileOrder(status) {
      if (Number(status) === 1) { // 充值中
        status = 1
      } else if (Number(status) === 2) { // 已完成
        status = 2
      }
      let orderType
      if (this.orderType === 2) { // 手机充值
        orderType = 711
      } else if (this.orderType === 3) { // 视频充值
        orderType = 701
      }
      getOrderv2List({
        page: this.page,
        pageSize: 10,
        orderType: orderType, // 701,视频711手机
        mid: this.mid, // 1102762,
        status: status// 0,//默认0，代付款5充值中10，已完成20
      }).then(res => {
        if (Number(res.code) === 200) {
          if (res.data.length < 1 || !res.data) {
            this.isData = true
            this.finished = true
            this.orderList = []
            return
          } else if (res.data.length < 10) {
            this.isData = false
            this.finished = true
            if (this.page > 1) {
              this.orderList = this.orderList.concat(res.data)
            } else {
              this.orderList = res.data
            }
            return
          } else {
            this.isData = false
            if (this.page > 1) {
              this.orderList = this.orderList.concat(res.data)
            } else {
              this.orderList = res.data
            }
          }
          this.loading = false
          this.refreshing = false
        } else {
          Toast(res.msg)
        }
      })
    },
    // 手机/视频取消订单
    CancelOrder(item) {
      cancelV2Order({
        orderNo: item.orderNo
      }).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
          item.status = 25
          item.statusText = '已取消'
        } else {
          Toast(res.msg)
        }
      })
    },
    // 手机待支付发起支付
    mobilePayOrder(item) {
      getMobileTopUpPay({
        phone: item.mobile,
        id: this.p_id,
        openId: localStorage.getItem('openid'),
        payChannel: 3,
        payType: 1,
        payVersion: 4,
        order_sn: item.orderNo
      }).then((res) => {
        if (Number(res.code) === 200) {
          const params = res.data.getwayBody
          this.wxPay(params)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 视频待支付发起支付
    videoPayOrder(item) {
      getVideoTopUpPay({
        mobile: item.mobile,
        id: this.p_id, // 可选
        returnUrl: 'https://www.yuetao.group',
        openId: 'o76iJtxdxZ8hv7z7MQIzrvsWfG_c',
        payChannel: 3, //   1  原生支付   2   银联支付  3  畅通付  h5调用3
        payType: 1, // 1 微信公众号 2  微信app   3 支付宝H5支付  4 支付宝 app  5 微信小程序
        payVersion: 4, // 3  微嘉付  4    畅通付
        order_sn: item.orderNo
      }).then((res) => {
        if (Number(res.code) === 200) {
          const params = res.data.getwayBody
          this.wxPay(params)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 视频banner
    getVideoBanner() {
      videoOrderBanner().then(res => {
        if (Number(res.code) === 200) {
          this.videoBannerImg = (res.data)[0].url
        } else {
          Toast(res.msg)
        }
      })
    },
    // 视频详情
    goVideoDetail(item) {
      this.$router.push({
        path: '/videoDetails',
        query: {
          orderNo: item.orderNo
        }
      })
    },
    // 解决微信内置对象报错
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
        that.isOrderType('TRADE_SUCCESS')
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            that.isOrderType('UNKNOWN')
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            that.isOrderType('UNKNOWN')
          }
        }
        that.isDisabledSubmitBtn = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #F8F8F8;
}

.content {
  position: absolute;

  .OrderListContent {
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
    padding: 10px;
    /*美团*/

    .orderDetails {
      width: 100%;
      background-color: #fff;
      border-radius: 7px;
      padding: 10px;
      text-align: left;
      margin-bottom: 10px;

      .orderSn {
        font-size: 12px;
        color: #000000;
        border-bottom: 1px solid #cccccc;
        padding: 5px;

        span {
          display: inline-block;
          width: 35px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          margin-left: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
      }

      .orderContentWrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        img {
          width: 100px;
          height: 100px;
        }

        .orderContent {
          width: 65%;
          margin-left: 10px;

          .orderName, .orderTitle {
            display: flex;
            flex-wrap: wrap;
          }

          .orderName {
            p {
              margin-left: 10px;
              width: 85%;
              font-size: 12px;
              font-weight: 700;
              line-height: 15px;
            }
          }

          .orderTitle {
            margin-top: 19px;
            font-size: 12px;

            div p:nth-child(1) {
              font-size: 12px;

              span {
                display: inline-block;
                transform: scale(0.8);
                color: #666666;
              }
            }

            div:nth-child(2) {
              margin-left: auto;
            }
          }

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .orderBtm {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;

        .orderDate {
          color: #999999;
          font-size: 13px;
        }

        .orderStatus {
          margin-left: auto;
          color: #E33F44;
        }
      }
    }

    /*视频充值banner*/

    .videoBanner {
      width: 100%;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    /*视频充值*/

    .videoTopUpOrderDetails {
      padding: 10px;
      width: 100%;
      text-align: left;
      background-color: #fff;
      border-radius: 7px;
      margin-bottom: 10px;
      font-size: 12px;
      font-family: 'PingFang SC Regular';

      .videoTopUpNameStatus {
        display: flex;

        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        p:nth-child(2) {
          font-size: 16px;
          font-weight: 500;
          color: #333;
        }

        div {
          font-size: 13px;
          font-weight: 500;
          color: #E33F44;
          margin-left: auto;

        }
      }

      .videoTopUpNumber {
        font-size: 12px;
        color: #999999;
        margin-top: 14px;
      }

      .videoTopUpDate {
        font-size: 12px;
        color: #999999;
        margin-top: 10px;
      }

      .videoTopUpSpecifications {
        display: flex;
        margin-top: 10px;

        p:nth-child(1) {
          font-size: 12px;
          color: #999999;
        }

        p:nth-child(2) {
          font-size: 13px;
          color: #333;
          margin-left: auto;
        }
      }
    }

    /*手机充值*/

    .TopUpOrderDetails {
      padding: 13px 10px;
      width: 100%;
      text-align: left;
      background-color: #fff;
      border-radius: 7px;
      margin-bottom: 10px;
      font-size: 12px;

      .TopUpNameStatus {
        display: flex;

        p:nth-child(1) {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }

        div {
          margin-left: auto;

          p {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }

      .TopUpNumber {
        margin-top: 13px;
        font-size: 12px;
        color: #999999;
      }

      .TopUpDatePrice {
        display: flex;
        margin-top: 10px;

        p:nth-child(1) {
          font-size: 12px;
          color: #999999;
        }

        p:nth-child(2) {
          margin-left: auto;
          font-size: 13px;
          color: #333333;
          font-weight: 400;
        }
      }
    }
  }

  .noDataWrap {
    text-align: center;
    margin-top: 150px;

    .noDataImg {
      width: 150px;
      height: 150px;
    }

    .noDataText {
      font-size: 14px;
      color: #ccc;
      margin-top: 20px;
    }
  }

  .PayBtnWrap {
    border-top: 1px solid #F2F2F2;
    padding: 13px 0px 0px;
    margin-top: 13px;
    overflow: hidden;

    .PayBtn {
      float: right;

      p {
        width: 85px;
        height: 30px;
        border-radius: 15px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        float: left;
      }

      p:nth-child(1) {
        border: 1px solid #969696;
        color: #999999;
        margin-right: 6px;
      }

      p:nth-child(2) {
        color: #ffffff;
        background-color: #E43F44;
      }
    }
  }
}
</style>
